/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

/* Full screen background */
.weather-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: #fff;
    text-align: center;
}

/* Background image for specific weather conditions */
.weather-app.clouds {
    background-image: url('./images/clouds-1.png');
}

/* Container for search box and results */
.form-form-container {
    max-width: 600px;
    min-height: 200px; /* Set minimum height */
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 2px solid transparent;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center contents vertically */
}

/* Apply the font to the input fields */
#cityInput, #stateInput {
    border: none; /* Remove border */
    border-bottom: 2px solid #ccc; /* Add bottom border */
    padding: 15px;
    font-size: 1.2rem;
    font-family: 'Play', sans-serif;
    background-color: transparent;
    margin-bottom: 10px;
    width: calc(100% - 30px);
    box-sizing: border-box;
}

/* Change bottom border color on focus */
#cityInput:focus, #stateInput:focus {
    outline: none; /* Remove default focus outline */
    border-bottom-color: #007bff; /* Change bottom border color on focus */
}

label {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #333;
    width: 100%;
    text-align: left;
}

weather-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 10px;
}

.weather-data {
    margin-top: 20px;
}

.weather-data img {
    margin-top: 10px;
}

.weather-data p, .weather-data h1 {
    color: #333;
}

/* Align paragraph items to the left */
.align-left {
    text-align: left;
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: column;
}

@media (max-width: 600px) {
    .form-form-container {
        padding: 10px;
    }
}
