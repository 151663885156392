/* Header.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: white;
  position: sticky; /* Makes the header sticky */
  top: 0; /* Sticks the header to the top */
  z-index: 1000; /* Ensures the header is above other content */
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  gap: 1rem;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links a:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Add glow effect on hover */
}

/* Logo Section "Welcome!" */
.navbar-logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.navbar-logo a:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Add glow effect on hover */
}

.hamburger {
  display: none; /* Hidden by default, shown on mobile via media query */
  cursor: pointer; /* Change cursor to pointer when hovering */
}

.hamburger img {
  width: 25px; /* Adjust the size as needed */
  height: auto;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 60px; /* Adjust as per your header height */
    left: 0; /* Ensure the menu starts from the left edge */
    background-color: #333;
    width: 100%; /* Make sure it takes the full width */
    z-index: 10;
  }

  .navbar-links a {
    padding: 10px;
    text-align: center;
  }

  .navbar-links.active {
    display: flex; /* Show the links when active */
  }

  .hamburger {
    display: flex; /* Show the hamburger menu */
  }
}
