/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Trade+Winds&display=swap');


body {
  font-family: 'Play', sans-serif;
}

.header-projects{
    text-align: center;
    padding-top: 25px;
    text-decoration: underline;
    font-family: 'Trade Winds', sans-serif;
}

.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 100px));
  /* gap: 20px; */
  /* padding: 20px; */
  /* padding-top: 50px; */
  justify-content: center; /* Center the grid items horizontally */
  flex-grow: 1;
  margin-bottom: 80px;
}

.project-item {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center;
  padding: 75px;
}

.project-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1; /* Ensure the image is square */
  object-fit: cover; /* Ensure the image covers the entire square area */
  border-radius: 8px;
  margin-bottom: 20px;
}

.project-item h3 {
  margin: 0;
  font-size: 1.2rem;
}

.projects-container {
  flex: 1;
  padding: 20px;
}