.bottom-container {
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 20px;
  text-align: center;
  z-index: 10;
}

/* Gradient Background */
.gradient-background {
  background: linear-gradient(300deg, #000000, #4d4a4a, #000000);
  background-size: 200% 200%;
  animation: gradient-animation 18s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.footer-link {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-link:last-child {
  margin-right: 0;
}

.footer-link:not(:last-child)::after {
  content: '|';
  margin-left: 10px;
  color: #ddd;
}

.quote-container {
  max-width: 600px;
  margin: 0 auto;
}

.quote-text {
  font-family: "Yellowtail", cursive;
  font-size: 1.8em;
  color: #fff;
}

.quote-author {
  font-size: 1em;
  font-weight: bold;
  color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px; /* Reduce space between paragraphs */
}

.refresh-button {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.refresh-icon {
  width: 30px; /* Increase the size of the refresh icon */
  height: 30px;
}

.copyright {
  color: #ddd;
  margin-top: 10px;
}
