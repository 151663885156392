/* hamburger.css */

.hamburger {
    display: none; /* Hidden by default, shown on mobile via media query */
    cursor: pointer; /* Change cursor to pointer when hovering */
  }
  
  .hamburger img {
    width: 25px; /* Adjust the size as needed */
    height: auto;
  }
  
  @media (max-width: 768px) {
    .hamburger {
      display: block; /* Show the hamburger menu */
    }
  }
  