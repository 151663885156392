/* Styles for the contact form wrapper */
.contact-form-wrapper {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align items to the top */
    align-items: center; /* Center items horizontally */
    height: calc(100vh - 20px); /* Set wrapper height to 100% viewport height minus some padding */
    margin-top: 20px; /* Add some margin to move it down from the top */
  }
  
  .h1contact {
    color: #000;
    font-family: 'Dancing Script', cursive;
    font-size: 3.625rem;
    font-style: italic;
    margin-bottom: 25px;
    /* text-shadow: -1px 2px 0 #000,
    1px 1px 0 #000,
    1px -1px 0 #000,
    -1px -1px 0 #000; */
  }

  .form-label{
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 2rem;
  }

  .form-input{
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 2rem;
  }
  
  /* Styles for the <p> tag */
  .contact-heading {
    text-align: center; /* Center align the text */
    margin-bottom: 20px; /* Add some space below the <p> tag */
  }
  
  /* Styles for the contact form */
  .contact-form {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; /* Ensure the form takes full width of container */
    max-width: 400px; /* Limit the maximum width of the form */
  }
  
  /* Additional styles as needed */
  .contact-form label {
    align-self: flex-start;
    margin-bottom: 5px;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%; /* Take up full width */
    padding: 8px; /* Reduce padding */
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s, box-shadow 0.3s;
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
    font-size: 18px; /* Adjust font size */
  }
  
  .contact-form input[type="submit"] {
    width: 100%; /* Take up full width */
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: linear-gradient(300deg, #000000, #4d4a4a, #000000);
    background-size: 200% 200%;
    animation: gradient-animation 18s ease infinite;
    font-family: 'Dancing Script', cursive;
    font-size: 1.8rem;
  }
  
  .contact-form input[type="submit"]:hover {
    background: linear-gradient(300deg, #18e905, #56ac20, #18e905);
    background-size: 200% 200%;
    animation: gradient-animation 18s ease infinite;
  }
  
  submit-btn{
    font-family: 'Dancing Script', cursive;
  }

  .contact-form input[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .contact-form {
      padding: 10px;
      overflow-x: hidden; /* Hide horizontal overflow */
    }
  }
  

  .contact-image-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .contact-image {
    max-width: 100%;
    height: 200px;
  }
  
  .state-message {
    white-space: pre-line; /* Ensures line breaks are respected */
    text-align: center;
  }
  