/* Global Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-image: url('assets/images/marble-background-3.png');
  background-size: 100%;
  background-position: center;
  background-attachment: fixed;
}

html, body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Center container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Styles for the header section */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Styles for links */
.App-link {
  color: #61dafb;
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  /* Fix for mobile background */
  body {
    background: none;
    position: relative;
  }

  body::before {
    content: "";
    background-image: url('assets/images/marble-background-3.png');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.resume-container {
  flex: 1;
}

.bottom-container {
  margin-top: auto;
  background-color: #333;
  padding: 20px;
  text-align: center;
  color: white;
  width: 100%;
}

.footer-link {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-link:last-child {
  margin-right: 0;
}

.footer-link:not(:last-child)::after {
  content: '|';
  margin-left: 10px;
  color: #ddd;
}
