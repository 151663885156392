/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Full screen background */
.band-generator-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Use min-height instead of height to allow for content overflow */
    background-color: #f0f0f0; /* Light background */
    color: #333; /* Dark text color */
    font-family: 'Roboto', sans-serif; /* Use Roboto font for modern look */
    padding: 20px;
}

/* Container for the band generator */
.band-generator-container {
    background-color: #fff; /* White background */
    border-radius: 8px;
    padding: 40px; /* Increase padding for better spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333; /* Dark header color */
}

.band-button {
    background-color: #007bff; /* Primary button color */
    border: none;
    color: #fff; /* White text color */
    padding: 12px 24px; /* Increase padding for better clickability */
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

pBand {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #666; /* Medium text color */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .band-generator-container {
        padding: 20px; /* Reduce padding for smaller screens */
    }

    h1 {
        font-size: 2rem;
    }

    button {
        font-size: 1rem;
    }

    p {
        font-size: 1.2rem;
    }
}
