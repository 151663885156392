bodySimon {
  text-align: center;
  background-color: #011F3F;
}

.row {
  display: flex;
  justify-content: center;
}

#level-title {
  font-family: 'Press Start 2P', cursive;
  font-size: 3rem;
  margin: 5%;
  color: #FEF2BF;
}

.container {
  display: block;
  width: 50%;
  margin: auto;
}

.alignP {
  margin-bottom: 100px;
}

.btnSimon {
  margin: 10px;
  display: inline-block;
  height: 200px;
  width: 200px;
  border: 10px solid black;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSimon.pressed {
  box-shadow: 0 0 20px white;
  background-color: grey;
}

.btntext {
  outline: none;
  font-size: 5rem;
  font-family: 'Arvo', cursive;
  font-weight: 900;
  text-shadow: 3px 0 #DBEDF3;
  text-align: center;
  margin: 10px;
  background-color: white;
  align-items: center;
}

.game-over {
  background-color: red;
  opacity: 0.8;
}

.red {
  background-color: red;
}

.green {
  background-color: green;  
}

.blue {
  background-color: blue;
}

.yellow {
  background-color: yellow;
}

.pressed {
  box-shadow: 0 0 20px white;
  background-color: grey;
}

.button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 400px;
  margin: 0 auto;
}

.bottom-container {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  position: static;
}

.simon-game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  padding: 50px;
  color: #fff;
  text-align: center;
  background-color: #011F3F;
}

@media (max-width: 768px) {
  #level-title {
    font-size: 2rem;
  }

  .button-container {
    /* width: 80%; */
    grid-template-columns: repeat(2, 1fr);
  }

  .btnSimon {
    height: 100px;
    width: 100px;
    border: 8px solid black;
  }
}

@media (max-width: 480px) {
  .button-container {
    /* width: 100%; */
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .btnSimon {
    height: 80px;
    width: 80px;
    border: 6px solid black;
  }
}
