/* src/components/Resume.css */

body, html {
  height: 100%;
  margin: 0;
}

h2Resume {
  color: #66BFBF;
  text-align: center;
  font: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  padding-bottom: 10px;
}

.resume-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.resume-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 100px;
  flex: 1;
}

.resume-section {
  position: relative;
  z-index: 1; /* Ensure all resume sections have the same z-index */
}

.search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.resume-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 10px;
}

.resume-item:hover {
  transform: scale(1.02);
}

.resume-item h3 {
  margin: 0 0 10px 0;
  font-size: 1.5rem;
}

.company {
  font-weight: bold;
}

.years {
  font-style: italic;
}

.description {
  margin-top: 10px;
}

.resume-item {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  position: relative;
  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 30px;
  z-index: 1; /* Ensure all images within .resume-item appear on top */
}

.job-logo {
  top: -20px;
  left: -20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
}

.menu {
  position: relative;
  z-index: 10;
}

.bottom-container {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
}

.footer-link {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.copyright {
  margin-top: 10px;
}



/* Styles for enlarged image */
.ReactModal__Overlay {
  z-index: 10000 !important;
}

.ReactModal__Content {
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.EnlargedImage__content {
  position: relative;
  width: 5% !important;
  height: 5% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EnlargedImage__content img {
  max-width: 60vw !important; /* Adjust this value to your preference */
  max-height: 60vh !important; /* Adjust this value to your preference */
}